<template>
  <div class="manage-users-edit">
    <div class="container fluid">
      <div class="card p-3">
        <h2 class="mb-4">Edit User</h2>

        <user-form
          v-if="isLoaded"
          isEdit
          :roleOptions="roleOptions"
          :isRoleLoading="isRoleLoading"
          :user="form"
          @submit="updateUser"
        ></user-form>
      </div>

      <div class="card p-3 mt-2">
        <h4 class="col-12 mb-3">Password & Authentitcation</h4>
        <fd-button
          class="bordered main"
          @click="isChangePasswordModalOpened = true"
        >
          Change Password
        </fd-button>
      </div>
    </div>

    <!-- Change Password -->
    <modal v-model="isChangePasswordModalOpened">
      <change-password
        ref="changePassword"
        @update="updatePassword"
      ></change-password>
    </modal>
  </div>
</template>

<script>
import UserForm from "@/components/User/UserForm";
import { role as roleAPI, user as userAPI } from "@/api";
import { UserModel } from "@/models";
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

export default {
  setup(props, context) {
    let toDeveloperUserList = () => {
      context.root.$router.push({
        name: "ManageUsers"
      });
    };
    const submitHandlerOptions = {
      role: "developer user",
      successCallback: toDeveloperUserList
    };
    const { handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleSubmitError
    };
  },
  components: {
    UserForm,
    ChangePassword: () => import("@/components/ManageAccount/ChangePassword")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      isChangePasswordModalOpened: false,
      roleOptions: [],
      isRoleLoading: false,
      isLoaded: false,

      user: {},

      form: {
        name: "",
        email: "",
        phoneNo: {},
        roleId: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await Promise.all([this.getUser(), this.getRoles()]);
      this.$store.commit("setIsLoading", false);
    },
    async getUser() {
      try {
        this.$store.commit("setIsLoading", true);
        this.isLoaded = false;
        let data = await userAPI.getUser(this.$route.params.id);
        this.user = this._.cloneDeep(data);
        this.form = this._.cloneDeep(UserModel.getToEdit(data));
        this.isLoaded = true;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch user data. Please try again later."
        });
      }
    },
    async getRoles() {
      try {
        this.isRoleLoading = true;
        let res = await roleAPI.getRoles({});
        this.roleOptions = this._.cloneDeep(res.data);

        this.isRoleLoading = false;
      } catch (error) {
        console.log(error);
        this.isRoleLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch role options. Please try again later."
        });
      }
    },
    async updateUser(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await userAPI.updateUser(this.user.id, UserModel.putPayload(payload));
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "User profile has been updated successfully."
        });
        this.$store.commit("setIsLoading", false);
        this.getUser();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.handleSubmitError(error);
      }
    },
    async updatePassword(payload) {
      try {
        console.log(payload);
        this.$store.commit("setIsLoading", true);
        await userAPI.updateUserPassword(this.user.id, { password: payload });
        this.isChangePasswordModalOpened = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "User password has been updated successfully."
        });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update password. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
