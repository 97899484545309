<template>
  <div class="user-form">
    <fd-form @submit="$emit('submit', form)" class="row mxn-1">
      <h4 class="col-12 px-1 mb-3">Account Details</h4>

      <div class="col-12 row">
        <fd-select
          v-model="form.roleId"
          class="col-12 sm-col-6 md-col-3 px-1 mb-2"
          label="Role"
          :options="roleOptions"
          :isOptionDisabled="(opt) => opt.id == OWNER_ROLE_ID"
          :isLoading="isRoleLoading"
          :disabled="isRoleLoading || isRoleEditable"
          :validators="[validator.required]"
        >
        </fd-select>
      </div>
      <fd-input
        v-model="form.name"
        class="col-12 sm-col-6 md-col-3 px-1 mb-2"
        label="Name"
        name="developerUserName"
        type="text"
        :validators="[validator.required]"
      >
      </fd-input>
      <fd-input
        v-model="form.email"
        class="col-12 sm-col-6 md-col-3 px-1 mb-2"
        label="Email"
        name="developerUserEmail"
        type="email"
        :validators="[validator.required, validator.email]"
      >
      </fd-input>
      <fd-input
        v-if="!isEdit"
        v-model="form.password"
        class="col-12 sm-col-6 md-col-3 px-1 mb-2"
        label="Password"
        name="developerUserPassword"
        :type="isPasswordShown ? 'text' : 'password'"
        :validators="[validator.required, validator.password]"
      >
        <template #append-icon>
          <i
            :class="[
              isPasswordShown ? 'fas fa-eye-slash' : 'fas fa-eye',
              'cursor-pointer'
            ]"
            @click="isPasswordShown = !isPasswordShown"
          ></i>
        </template>
      </fd-input>
      <tel-input
        ref="tel"
        v-model="phoneInput"
        :tel.sync="form.phoneNo"
        class="col-12 px-1 mb-2 d-block"
        label="Contact No."
        required
      >
      </tel-input>

      <div class="col-12 px-1 text-right mt-3">
        <button class="btn main">{{ actionLabel }}</button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import {
  required,
  phone,
  email,
  password
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import TelInput from "@/modules/User/components/TelInput";

const OWNER_ROLE_ID = 1;

export default {
  components: { TelInput },
  mixins: [],
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object
    },
    isRoleLoading: {
      type: Boolean,
      default: false
    },
    roleOptions: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      isPasswordShown: false,
      phoneInput: "",
      form: {
        name: "",
        roleId: "",
        email: "",
        password: "",
        phoneNo: {}
      },

      validator: {
        required,
        phone,
        email,
        password
      },
      OWNER_ROLE_ID
    };
  },
  computed: {
    actionLabel() {
      return this.isEdit ? "Save" : "Create";
    },
    isRoleEditable() {
      return this.isEdit && this.form.roleId == OWNER_ROLE_ID;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    if (this.isEdit) {
      this.init();
    }
  },
  methods: {
    init() {
      this.form = this._.cloneDeep(this.user);
      this.$refs.tel.setTel(this.form);
    }
  }
};
</script>

<style lang="scss"></style>
